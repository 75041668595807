import React, { ReactElement } from "react";
import {
  FluidSharpImageFile,
  GatsbyMarkdownFile,
  MarkdownContent,
} from "@ymcansw-camping/common";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import CommunityLayout from "../../components/community-layout";
import ContactImageLinkBox from "../../components/contact-image-link-box";
import DonateImageLinkBox from "../../components/donate-image-link-box";

type CommunityInspireData = {
  readonly pageFile: GatsbyMarkdownFile<{
    readonly title: string;
    readonly image: FluidSharpImageFile;
  }>;
};

export default function InspirePage(): ReactElement {
  const {
    pageFile: {
      childMarkdownRemark: { frontmatter, rawMarkdownBody },
    },
  } = useStaticQuery<CommunityInspireData>(graphql`
    {
      pageFile: file(relativePath: { eq: "pages/community-inspire.md" }) {
        childMarkdownRemark {
          rawMarkdownBody
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 311) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <CommunityLayout
      meta={{
        title: "Inspire Holiday Camps",
        description:
          "If you’re looking for healthy, active and exciting holiday care for your child, The Y's School Holiday Camp Programs are for you.",
      }}
    >
      <div className="content-block">
        <h2>{frontmatter.title}</h2>
        <div className="row">
          <div className="col-xs-12 col-sm-5 col-sm-push-7">
            <div className="image-container">
              <Img
                fluid={frontmatter.image.childImageSharp.fluid}
                alt={frontmatter.title}
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-7 col-sm-pull-5">
            <MarkdownContent>{rawMarkdownBody}</MarkdownContent>
          </div>
        </div>
      </div>
      <div className="content-block with-border-top image-link-box-container">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <ContactImageLinkBox />
          </div>
          <div className="col-xs-12 col-sm-6">
            <DonateImageLinkBox />
          </div>
        </div>
      </div>
    </CommunityLayout>
  );
}
